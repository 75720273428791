import React from 'react'
import styled from 'styled-components'
import Layout from '../components/layout'
import KeyVisual from '../components/modules/visuals/TheKeyVisual'
import { SectionTitle } from '../components/tokens/fontStyles'
import Profile from '../components/modules/containers/ProfileContainer'
import Articles from '../components/organisms/ArticlesContainer'
import SEO from '../components/seo'
import { graphql } from 'gatsby'
import breakpoints from '../components/breakpoints.js'

export const query = graphql`
  {
    microcmsCeoProfile {
      id
      name
      position
      profile
      comment
      copy
      image {
        url
      }
      articles {
        title
        text
        image {
          url
        }
      }
      kv {
        url
      }
    }
  }
`
const IndexPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="CEO" />
      <KeyVisual
        title="CEO Message"
        image={data.microcmsCeoProfile.kv.url}
        ceo={true}
      />
      <Title>{data.microcmsCeoProfile.copy}</Title>
      <Wrapper>
        <Profile data={data.microcmsCeoProfile} />
        <Articles data={data.microcmsCeoProfile.articles} />
      </Wrapper>
    </Layout>
  )
}

const Title = styled.h2`
  ${SectionTitle}
  max-width: 980px;
  margin: 80px auto;
  ${breakpoints.lessThan('pc')`
padding: 0 20px 20px;
`}
`

const Wrapper = styled.section`
  max-width: 980px;
  margin: 0 auto;
  padding: 0px 40px;
  ${breakpoints.lessThan('pc')`
padding: 0 20px;
`}
`
export default IndexPage
